@import url(https://fonts.googleapis.com/css2?family=Inter:wght@400;600;700&display=swap);

/* body {
    background-color: #e9ebee !important;
    font-family: "Poppins", sans-serif !important;
} */

/* html, body, #root {
    width: 100% !important;
    height: 100% !important;
} */

html, body, #root {
    width: 100% !important;
    height: 100% !important;
    
}

a {
    text-decoration: none;
    color: inherit
}

/* React Tour */
.helper {
    padding: 16px !important;  
    width: 310px !important;
}
.diKbXs {
    display: flex !important;
    align-items: center !important;
    justify-content: flex-end !important;
    margin-top: 8px !important;
}

.btn-cls {
    color: #989FA8 !important;
    font-size: 12px !important;
    
}
.btn-nxt {
    background-color: #4A81FD !important;
    color: #fff !important;
    font-size: 12px !important;
}

.input-container input{ 
    border:0;
    border-bottom:1px solid #fff;  
    background:transparent;
    width:100%;
    font-size:16px;
    font-family: Poppins;
    color:#fff;
  }
  .input-container input:focus{ 
   border:none;	
   outline:none;
   border-bottom:1px solid #fff;	
  }

.input-date input{ 
    display: flex;
    align-items: center;
    border:0;
    background:transparent;
    font-size:16px;
    font-family: Poppins;
    color:#fff;
    width: 100px !important;
  }
  .input-date input:focus{ 
   border:none;	
   outline:none;
  }


.personal-logo {
    width: 7rem;
    max-width: 7rem;
    height: 7rem;
    max-height: 7rem;
    margin-top: -5rem;
    border: 3px solid #fff;
    border-radius: 10%;
    background-color: #fff;
    background-size: cover;
    background-position: center center
}

.speck {
    width: 1.4rem;
    max-width: 1.4rem;
    height: 1.4rem;
    max-height: 1.4rem;
    margin-top: -1.4rem;
    border: 3px solid #fff;
    border-radius: 10%;
    background-color: #fff;
    background-size: cover;
    background-position: center center
}

.xxs {
    width: 2.5rem;
    max-width: 2.5rem;
    height: 2.5rem;
    max-height: 2.5rem;
    margin-top: -2.5rem;
    border: 3px solid #fff;
    border-radius: 10%;
    background-color: #fff;
    background-size: cover;
    background-position: center center
}

.xs {
    width: 3rem;
    max-width: 3rem;
    height: 3rem;
    max-height: 3rem;
    margin-top: -3rem;
    border: 3px solid #fff;
    border-radius: 10%;
    background-color: #fff;
    background-size: cover;
    background-position: center center
}

.sm {
    width: 5rem;
    max-width: 5rem;
    height: 5rem;
    max-height: 5rem;
    margin-top: -5rem;
    border: 3px solid #fff;
    border-radius: 10%;
    background-color: #fff;
    background-size: cover;
    background-position: center center
}

.lg {
    width: 10rem;
    max-width: 10rem;
    height: 10rem;
    max-height: 10rem;
    margin-top: -6rem;
    border: 3px solid #fff;
    border-radius: 10%;
    background-color: #fff;
    background-size: cover;
    background-position: center center
}

.xl {
    width: 13rem;
    max-width: 13rem;
    height: 13rem;
    max-height: 13rem;
    margin-top: -5rem;
    border: 3px solid #fff;
    border-radius: 10%;
    background-color: #fff;
    background-size: cover;
    background-position: center center
}

.xxl {
    width: 18rem;
    max-width: 18rem;
    height: 18rem;
    max-height: 18rem;
    margin-top: -5rem;
    border: 3px solid #fff;
    border-radius: 10%;
    background-color: #fff;
    background-size: cover;
    background-position: center center
}

.px-1 {
    width: 40px;
    max-width: 40px;
    height: 40px;
    max-height: 40px;
    border-radius: 10%;
    background-size: cover;
    background-position: center center
}

.px-2 {
    width: 30px;
    max-width: 30px;
    height: 30px;
    max-height: 30px;
    border-radius: 10%;
    background-size: cover;
    background-position: center center
}

.flat-top {
    margin-top: 0 !important
}

.shadow {
    box-shadow: 0 .5rem 1rem rgba(0, 0, 0, .15) !important
}

.shadow-sm {
    box-shadow: 0 .2rem 0.5rem rgba(0, 0, 0, .20) !important
}

.shadow-lg {
    box-shadow: 0 1rem 3rem rgba(0, 0, 0, .175) !important
}

.shadow-none {
    box-shadow: none !important
}

.shadow-switch {
    box-shadow: 0 0 .4rem rgba(0, 0, 0, 16%) !important
}

.w-25 {
    width: 25% !important
}

.c-pointer {
    cursor: pointer;
}

.swiper-button-next:after, .swiper-button-prev:after {
    font-size: 10px !important;
    color: #292D32 !important;
    padding: 10px 15px !important;
    box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, .68) !important;
    border-radius: 7px !important;
    background-color: #FFFFFF !important;
}

.swiper-button-disabled, .swiper-button-disabled {
    opacity: 0!important;
}

.swal2-container {
    z-index: 1102 !important;
}

.swiper-container {
    z-index: 0 !important;
}

.react-joyride__spotlight{
    background-color: rgb(255 255 255 / 35%) !important;
    border: solid 2px white;
    border-radius: 11px;
}

.text-justify {
    text-align: justify !important;
}

.receipt-body-1 {
    position: relative;
}

.receipt-body-1:before {
    content: "";
    display: block;
    background: #EBF2F0;
    position: absolute;
    bottom: -18px;
    left: -18px;
    width: 50px;
    height: 50px;
    border-radius: 50%;
}
.receipt-body-1:after{
    content: "";
    display: block;
    background: #EBF2F0;
    position: absolute;
    bottom: -18px;
    right: -18px;
    width: 50px;
    height: 50px;
    border-radius: 50%;
}

.receipt-body-2 {
    position: relative;
}

.receipt-body-2:before {
    content: "";
    display: block;
    background: #fff;
    position: absolute;
    bottom: -18px;
    left: -18px;
    width: 50px;
    height: 50px;
    border-radius: 50%;
}
.receipt-body-2:after{
    content: "";
    display: block;
    background: #fff;
    position: absolute;
    bottom: -18px;
    right: -18px;
    width: 50px;
    height: 50px;
    border-radius: 50%;
}

.container {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-width: 400px;
}

.paypal {
    z-index: 1;
}

.cover-paypal {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
    pointer-events: none;
}

.overflow-x-auto {
    overflow-x: auto;
}

.overflow-y-auto {
    overflow-y: auto;
}
.overflow-Y {
    overflow-y: scroll;
}
.otp-sm-inputs {
    font-size: 24px;
    margin: 5px;
    min-width: 55px;
    height: 70px;
    border: 1px solid transparent;
    border-radius: 10px;
    caret-color: transparent;
    box-shadow: 0 0.3rem 0.5rem rgba(0, 0, 0, .15);
}

.otp-sm-inputs.bg-sm-biz:focus {
    outline: 1px solid #11783C;
    box-shadow: 0 0.3rem 0.5rem rgba(0, 0, 0, .15), inset 0px 0px 0px 15px #ffffff, inset 0px -20px 0px 0px #11783C !important;
}

.otp-sm-inputs.bg-sm-psl:focus {
    box-shadow: 0 0.3rem 0.5rem rgba(0, 0, 0, .15), inset 0px 0px 0px 15px #ffffff, inset 0px -20px 0px 0px #0070EF !important;
}

.bg-sm-biz {
    border: 1px solid transparent
}

.bg-otp-mismatched {
    border: 1px solid #f44336
}
/* width */
::-webkit-scrollbar {
  width: 0px;
  height: 0px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1; 
}
 
/* Handle */
::-webkit-scrollbar-thumb {
  background: #092D77; 
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #092D77; 
}
